import React, { useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import carousel1 from "../../assets/images/SS-collageImage.jpg";
import "./Home.css";
import chairman from "../../assets/chairman1.jpg";
import principal from "../../assets/chairmanmi.jpg";
import welcome from "../../assets/welimg.avif";
import secretary from "../../assets/Secretary.jpg";
import cse from "../../assets/cse .jpg";
import ece from "../../assets/ece.jpg";
import mech from "../../assets/mech.jpg";
import eee from "../../assets/eee.jpg";
import civil from "../../assets/civil.jpg";
import mba from "../../assets/course-1.jpg";
import { Link } from "react-router-dom";
import Header from "../Header/Header";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "animate.css";
import mou1 from "../../assets/new_MOUs/1.png";
import mou2 from "../../assets/new_MOUs/2.jpg";
import mou3 from "../../assets/new_MOUs/3.jpg";
import mou4 from "../../assets/new_MOUs/4.png";
import mou5 from "../../assets/new_MOUs/5.jpg";
import mou6 from "../../assets/new_MOUs/6.jpg";
import mou7 from "../../assets/new_MOUs/7.jpg";
import mou8 from "../../assets/MOUs/wipro_logo .png";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import { FluentHatGraduationSparkle28Filled } from "../../assets/icons/svgs";
import { MdiAccountGraduationOutline } from "../../assets/icons/svgs";
import PolicyIcon from "@mui/icons-material/Policy";
import "aos/dist/aos.css";
import AOS from "aos";

// carousal images
import AspectRatio from "@mui/joy/AspectRatio";
import Card from "@mui/joy/Card";
import CardContent from "@mui/joy/CardContent";
import Typography from "@mui/joy/Typography";
// import carousal_img1 from "../../assets/carousal_images/cat-1.jpg";
// import carousal_img2 from "../../assets/mc1.jpg";
import carousal_img3 from "../../assets/carousal_images/Affiliations.jpg";
import carousal_img4 from "../../assets/carousal_images/c2.jpeg";
import carousal_img5 from "../../assets/carousal_images/Autonomous.jpg";
import carousal_img6 from "../../assets/carousal_images/College Photo.jpg";
import carousal_img7 from "../../assets/carousal_images/c8.jpg";
import carousal_img8 from "../../assets/carousal_images/Felicitation - JNTUK.jpg";
import carousal_img9 from "../../assets/carousal_images/Felictation-APSCHE.jpg";

// import pop_up from "../../assets/Academics/SMCE_jul31.jpeg"
// import carousal_img10 from "../../assets/carousal_images/c";
import pop_up from "../../assets/Academics/smce_popup.jpeg"

// management images
import chairman_img from "../../assets/Management/chairman.jpg";
import about from "../../assets/carousal_images/c8.jpg";
import secretary_img from "../../assets/Management/secretary.jpg";
import cse_img from "../../assets/Trustee-2.png";
import NotificationPannel from "../NotificationPannel/NotificationPannel";
import aiml from "../../assets/aimlimg.jpg";
import aid from "../../assets/images.jpg";
// import eceimgclassroom from "../../assets/ececlass.jpg";
import eeeclassroom from "../../assets/eeeclassroom.jpg";
import ececlass from "../../assets/ececlass.jpg";
import sh from "../../assets/s&himg.jpg";
import Accordion from "@mui/material/Accordion";
import AccordionActions from "@mui/material/AccordionActions";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Button from "@mui/material/Button";
import SchoolIcon from "@mui/icons-material/School";
import { orange } from "@mui/material/colors";

import About from "../About/About";
import Vision from "./Vision";

// import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import { useState } from "react";
import { useRef } from "react";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});


const Home = () => {

  const [open, setOpen] = useState(false);
  const marqueeRef = useRef(null);

  const handleMouseOver = () => {
    if (marqueeRef.current) {
      marqueeRef.current.stop();
    }
  };

  const handleMouseOut = () => {
    if (marqueeRef.current) {
      marqueeRef.current.start();
    }
  };

  var settings = {
    dots: true,
    infinite: true,
    // slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3500,
    pauseOnHover: false,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  const handleClickOpen = () => {
    setOpen(false);
  }
  useEffect(() => {
    AOS.init({
      // Global settings:
      disable: false, // accepts following values: 'phone', 'tablet', 'mobile', boolean, expression or function
      startEvent: "DOMContentLoaded", // name of the event dispatched on the document, that AOS should initialize on
      initClassName: "aos-init", // class applied after initialization
      animatedClassName: "aos-animate", // class applied on animation
      useClassNames: false, // if true, will add content of `data-aos` as classes on scroll
      disableMutationObserver: false, // disables automatic mutations' detections (advanced)
      debounceDelay: 300, // the delay on debounce used while resizing window (advanced)
      throttleDelay: 200, // the delay on throttle used while scrolling the page (advanced)

      // Settings that can be overridden on per-element basis, by `data-aos-*` attributes:
      offset: 450, // offset (in px) from the original trigger point
      // delay: 100, // values from 0 to 3000, with step 50ms
      duration: 1000, // values from 0 to 3000, with step 50ms
      easing: "ease", // default easing for AOS animations
      once: false, // whether animation should happen only once - while scrolling down
      mirror: false, // whether elements should animate out while scrolling past them
      anchorPlacement: "top-bottom", // defines which position of the element regarding to window should trigger the animation
    });

    // setOpen(true);
  }, []);
  let carousal_images = [
    {
      image: carousal_img9,
    },
    // {
    //   image: carousal_img1,
    // },
    // {
    //   image: carousal_img2,
    // },
    {
      image: carousal_img3,
    },
    {
      image: carousal_img4,
    },
    {
      image: carousal_img5,
    },
    {
      image: carousal_img6,
    },
    {
      image: carousal_img7,
    },
    {
      image: carousal_img8,
    },

    // {
    //   image: carousal_img10,
    // },
  ];
  // let management_images = [
  //   {
  //     image: chairman_img,
  //     position: "Chairman",
  //     name: "Dr. B. Pardha Saradhi Reddy",
  //   },
  //   {
  //     image: about,
  //     position: "about",
  //     name: "smce",
  //   },
  //   {
  //     image: secretary_img,
  //     position: "Secretary",
  //     name: "Sri D. Prabhakar Reddy",
  //   },
  //   {
  //     image: cse_img,
  //     position: "Trustee",
  //     name: "Smt. Bandi Anvida",
  //   },
  // ];

  const notifictaion=[
    {
      key:"27–09–2024 I B.Tech Sem–I Mid-I Examinations scheduled from 30–09–2024 onwards."
    },
    {
      key:"24–09–2024 An awareness programme to all NSS volunteers on the eve of NSS Day."
    },
    {
      key:"20–09–2024 I B.Tech Sem–I Assignment–I examinations scheduled from 23–09–2024 onwards."
    },
    {
      key:"19–09–2024 IV B.Tech Sem–I Mid-I Examination scheduled from 23–09–2024 onwards."
    },
    {
      key:"17–09–2024 A Five–Day workshop on POWER BI organizing by the AI & IT Dept."
    },
    {
      key:"14–09–2024 An online webinar on Current Trending Technologies, Placements and Projects by the TECH MINDSPARC Innovations."
    },
    {
      key:"13–09–2024 A Five–Day workshop on POWER BI and TABLEAU organizing by the CSE Dept."
    },
    {
      key:"12–09–2024 A Five Hands–On workshop on ARDUINO/IoT/EMBEDDED SYSTEMS organized by the ECE Dept."
    },
    {
      key:"03–09–2024 III B.Tech Sem–I Mid-I Examination scheduled from 09–09–2024 onwards."
    },
    {
      key:"23–08–2024 An One–day PERSONALITY DEVELOPMENT PROGRAM for II B.Tech Sem–I students by Smt. Bijaya Lakshmi Parimi garu."
    }
  ]
  return (
    <div className="App">
      <div>
        <div className="carousal_bg_cnt">
          <Slider {...settings}>
            {carousal_images.map((item, index) => {
              return (
                <div key={index}>
                  <div className="card-body">
                    <img
                      className=" carousal_img"
                      src={item.image}
                      alt="carousel1-img"
                    />
                  </div>
                </div>
              );
            })}
            {/* <div style={{ width: "100%"}}>
            <div className="card-body w-100">
              <img
                className="img-fluid carousal_img"
                src={carousel1}
                alt="carousel1-img"
              />
            </div>
          </div>

          <div style={{ width: "100%" }}>
            <div className="card-body w-100">
              <img
                className="img-fluid carousal_img"
                src={carousel1}
                alt="carousel1-img"
              />
            </div>
          </div>
          <div style={{ width: "100%" }}>
            <div className="card-body w-100">
              <img
                className="img-fluid carousal_img"
                src={carousel1}
                alt="carousel1-img"
              />
            </div>
          </div> */}
          </Slider>
        </div>
        {/* <div className="container">
        <div className="row-12">
          <div className="container about_text">
            <div className="row">
              <div className="col-12"></div>
              <div className="col-6 bg-light p-3">
                <p className="prg_content">
                  <h5>Welcome To SMCE</h5>
                  <br />
                  Sri Mittapalli College of Engineering, one of the top ranking
                  engineering college in India, has achieved global identity
                  through its involvement in research in recent technology. The
                  placements witnessed the college dedication about the studies,
                  activities and Descipline
                </p>
              </div>
              <div className="col-6 bg-light p-3 h-500px">
                <div className="img_cnt">
                  <img
                    className="img-fluid rounded "
                    src={welcome}
                    alt="welcome-img"
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="col-6"></div>
        </div>
      </div> */}
        <br />
        <div>
          <About />
        </div>

        <div style={{ width: "95%", margin: "auto" }}>

          <Vision />



        </div>

        <div>
          <h1 className="course_offerd">NOTIFICATIONS</h1>
        </div>
        {/* <NotificationPannel /> */}

        <marquee direction="up" className="notification_scroll"
          ref={marqueeRef}
          onMouseOver={handleMouseOver}
          onMouseOut={handleMouseOut}>
          {notifictaion.map((item)=><a href="#">
            {item.key}
          </a>)}
        </marquee>



        <div
          className="container-fluid py-5 mou_cnt_bg w-100"
          // data-aos="zoom-in"
          // data-aos-offset="200"
          // data-aos-easing="ease-in-sine"
          // data-aos-duration="1000"
        >


          {/* <div className="col-lg-12">
              <h6 className="text-start text-white pe-3">OUR MOUs</h6>
              <h1 className="mb-4 heading">Memorandum Of Understanding</h1>
              <div className="row gy-2 gx-4 mb-4">
                <div className="col-sm-6">
                  <p className="mb-0 text-white">
                    <i className="fa fa-arrow-right text-white me-2"></i>
                    Microsoft Windows
                  </p>
                </div>
                <div className="col-sm-6">
                  <p className="mb-0 text-white">
                    <i className="fa fa-arrow-right text-white me-2"></i>
                    CISCO Networking Academy
                  </p>
                </div>
                <div className="col-sm-6">
                  <p className="mb-0 text-white">
                    <i className="fa fa-arrow-right text-white me-2"></i>
                    TASK(Telangana Academy for Skill and Knowledge)
                  </p>
                </div>
                <br />
                <div className="col-sm-6">
                  <p className="mb-0 text-white">
                    <i className="fa fa-arrow-right text-white me-2"></i>
                    Wipro
                  </p>
                </div>
                <div className="col-sm-6">
                  <p className="mb-0 text-white">
                    <i className="fa fa-arrow-right text-white me-2"></i>
                    ESwetcha
                  </p>
                </div>
                <div className="col-sm-6">
                  <p className="mb-0 text-white">
                    <i className="fa fa-arrow-right text-white me-2"></i>
                    Campus Connect
                  </p>
                </div>
                <div className="col-sm-6">
                  <p className="mb-0 text-white">
                    <i className="fa fa-arrow-right text-white me-2"></i>
                    Oracle Academy
                  </p>
                </div>
                <div className="col-sm-6">
                  <p className="mb-0 text-white">
                    <i className="fa fa-arrow-right text-white me-2"></i>
                    Mission 10X
                  </p>
                </div>
              </div>
            </div> */}
          <div style={{ height: "5cm" }} className="col-lg-12">
            <h1 className="text-start heading  pe-3 ">
              <b>Memorandum of Understanding</b>
            </h1>
            <h5 className="mb-3 text-white">Our Partners are</h5>
            <div className="position-relative h-100">
              <marquee behavior="scroll" direction="left" scrollamount="15">
                <div className="d-flex flex-row align-items-center gap-5">
                  <div className="mou_img_cnt" >
                    <img src={mou1} alt="mou1" className="mou_img" style={{width:"6cm"}} />
                  </div>
                  <div className="w-200 mou_img_cnt">
                    <img src={mou2} alt="mou1" className="mou_img" style={{width:"6cm"}} />
                  </div>
                  <div className="w-300 mou_img_cnt">
                    <img src={mou3} alt="mou1" className="mou_img" />
                  </div>
                  <div className="w-300 mou_img_cnt">
                    <img src={mou4} alt="mou1" className="mou_img" style={{width:"5cm"}}/>
                  </div>
                  <div className="w-300 mou_img_cnt">
                    <img src={mou5} alt="mou1" className="mou_img" />
                  </div>
                  <div className="w-100 mou_img_cnt">
                      <img src={mou6} alt="mou1" className="mou_img" />
                    </div>
                    <div className="w-400 mou_img_cnt">
                      <img src={mou7} alt="mou1" className="mou_img" style={{width:"5cm"}} />
                    </div>
                  {/* <div className="w-100 mou_img_cnt">
                      <img src={mou8} alt="mou1" className="mou_img" />
                    </div> */}
                </div>
              </marquee>
            </div>
          </div>
        </div>
      </div>







      <React.Fragment>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClickOpen}
        aria-describedby="alert-dialog-slide-description"
      >
      {/* <DialogTitle sx={{ textAlign: "center",fontWeight:"bold" }}>{}</DialogTitle> */}
        <DialogContent>
          {/* <DialogContentText id="alert-dialog-slide-description">
            Click <b style={{color:"black",fontSize:"18px",fontWeight:"bold"}}>Login </b>To Start <b style={{color:"black",fontSize:"18px",fontWeight:"bold"}}>Mock EAMCET Test</b>
                       </DialogContentText> */}
                       <img src={pop_up} className="pop"/>   </DialogContent>
        {/* <DialogActions>
          <Button sx={{ color: "red" }} onClick={handleClickOpen}>Close</Button>
          <Button  href="https://s18181355.makebizservices.com" target="_blank">Login</Button>
        </DialogActions> */}
      </Dialog>
    </React.Fragment>
    </div>
  );
};

export default Home;
