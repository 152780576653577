import React from "react";
import "./Menubar.css";
import "./Header.css";
// import logo from "../../assets/logo2r.png";
import "./Header.css";
import "../../../node_modules/bootstrap/dist/css/bootstrap.min.css";
import NewReleasesIcon from "@mui/icons-material/NewReleases";
import UGC from "../../assets/UGC.jpg";
import AICTE from "../../assets/AICTE.png";
import ISO from "../../assets/ISO.png";
import Typography from "@mui/material/Typography";
import VisibilityIcon from "@mui/icons-material/Visibility";
import ecaplogo from "../../assets/ecap-graphic.png";
import NaacImg from "../../assets/SMCE/Naac.jpeg";
import nba from "../../assets/SMCE/NBA.pdf";
import jntu from "../../assets/SMCE/UGC_Autonomous.pdf";
import iso from "../../assets/SMCE/ISO.jpg";
import aicte from "../../assets/SMCE/AICTE.pdf";
import examsPortal from "../../assets/icons/exmstool.png";
import pdf32 from "../../assets/pdfsmittapalli/B.TECH-FEE-GO-23122020HE_MS48feestructre.pdf";
import MTECHCSE from "../../Pdf/course_syllabus/PG/M.Tech CSE Syllabus.pdf";
import MTECHVLSEES from "../../Pdf/course_syllabus/PG/M.Tech VLSI&ES Syllabus.pdf";
import MR23MBA from "../../Pdf/course_syllabus/PG/MR23_MBA I, II SEM SYLLABUS.pdf";

//UG Course Sructure
import UGMr23BTECH from "../../Pdf/course_syllabus/UG/MR23_B.Tech_ SYLLABUS.pdf";
import UGR20AI from "../../Pdf/course_syllabus/UG/R20 AI Syllabus.pdf";
import UGR20CSE from "../../Pdf/course_syllabus/UG/R20 CSE Syllabus.pdf";
import UGR20DS from "../../Pdf/course_syllabus/UG/R20 DS Syllabus.pdf";
import UGR20ECE from "../../Pdf/course_syllabus/UG/R20 ECE Syllabus.pdf";
import UGR20IT from "../../Pdf/course_syllabus/UG/R20 IT Syllabus.pdf";
import fee from "../../assets/pdfsmittapalli/FeeStructure.docx";
import banner from "../../assets/mittapalliheader.png";
import { Link, useLocation, useNavigate } from "react-router-dom";
import PQPBTech from "../../Pdf/PreviusQP/Autonomous_I B.Tech I Sem Question Papers_Feb-2024.pdf";
import ARUGMR23 from "../../Pdf/AcademicRegulation/UG/MR23_B.Tech_Acedemic-Regulations.pdf";
import ARUGR20 from "../../Pdf/AcademicRegulation/UG/R20 B-Tech Course Regulations.pdf";
import ARPGMBA from "../../Pdf/AcademicRegulation/PG/MBA-MR23 (R19)-REGULATIONS.pdf";
import ARPGMTech from "../../Pdf/AcademicRegulation/PG/M.Tech-MR23 (R19)_regulations.pdf";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
const handleOpenPdf = (pdf) => {
  window.open(pdf, "_blank");
};
const Menubar = () => {
  const location = useLocation();

  const filterArray = {
    // Home
    HOME: "/",

    // Administration
    ADMINISTRATION: "/about",
    ABOUTTRUST: "/aboutTrust",
    CHAIRMAN: "/Chairman",
    SECRETARY: "/secretary",
    DIRECTOR: "/director",
    VICEPRESIDENT: "/VicePresident",
    PRINCIPAL: "/Administration/principal",

    // Academics
    UG_AR_MR23: ARUGMR23,
    UG_AR_R20: ARUGR20,
    PG_AR_MTECH: ARPGMTech,
    PG_AR_MBA: ARPGMBA,
    ACADEMICCALENDARS: "/acadamics/acadamicCalender",

    // Course Structure & Syllabus
    UG_MR23BTECH: UGMr23BTECH,
    UG_R20AI: UGR20AI,
    UG_R20CSE: UGR20CSE,
    UG_R20DS: UGR20DS,
    UG_R20ECE: UGR20ECE,
    UG_R20IT: UGR20IT,
    PG_MTECH_CSE: MTECHCSE,
    PG_MTECH_VLSEES: MTECHVLSEES,
    PG_MR23_MBA: MR23MBA,

    // Admissions
    PROGRAMSOFFERED: "/programesofferd",
    ADMISSIONPROCEDURE: "/admissionprocedure",
    FEESTRUCTURE: fee,
    B_CATEGORY_APPLICATION: pdf32,

    // Departments
    DEPARTMENTSCSE: "/departments/cse",
    DEPARTMENTSCSAI: "/departments/ai&ds",
    DEPARTMENTSCSDS: "/departments/cse(al&ml)",
    DEPARTMENTSIT: "/departments/it",
    DEPARTMENTSECE: "/departments/ece",
    DEPARTMENTSSH: "/departments/s&h",
    DEPARTMENTSMBA: "/departments/mba",

    // Examination Cell
    CONTROLLEROFEXAMINATIONS: "/coe",
    EXAM_TIMETABLES: "#", // Placeholder link for exam timetables
    EXAM_RESULTS: "https://jntukresults.edu.in/",
    PREVIOUS_QUESTIONS_AUTONOMOUS: PQPBTech,
    PREVIOUS_QUESTIONS_JNTUK: PQPBTech, // Assuming same link
    CONTACT_INFORMATION: "/admissioncontact",

    // Student Corner
    DIGITALLIBRARY: "/amenities/library",
    STUDENTGRIEVANCECELL: "/student/SmceStudentGrivanceCell",
    CLASSROOMS: "/amenities/e-classRooms",
    LABORATORIES: "/amenities/ictFecilities/lab",
    HOSTEL: "/amenities/hostels",
    TRANSPORT: "/amenities/transport",
    NSS: "#", // Placeholder for NSS
    HEALTHCENTER: "/HealthCenter",
    AUDITORIUM: "#", // Placeholder for Auditorium
    CANTEEN: "/amenities/cafeteria",

    // Statutory Body
    GOVERNINGBODY: "/governingbody",
    ACADEMICCOUNCIL: "/academiccouncil",
    BOS: "/bos",
    FINANCECOMMITTEE: "/finance",
    AICTE: aicte, // AICTE PDF
    JNTUK: jntu, // JNTUK PDF
    NBA: nba, // NBA PDF
    NAAC: NaacImg, // NAAC PDF
    ISO: iso, // ISO PDF
    IQAC: "/iqac",
    IIC: "#", // Placeholder for IIC
    ANTIRAGGINGCOMMITTEE: "/antiRagging",
    COLLEGECOMMITTEES: "/committees",

    // NAAC
    NAAC_AQAR: "/naac/naacSsr",
    NAAC_ICT: "/naac/ict",
    NAAC_EXTENDEDPROFILE: "/naac/extendedprofile",

    // Gallery
    MITTAPALLI_MIRACE_PHOTOS: "/gallery/campusPhotos",
    ALUMNI_MEET_PHOTOS: "/gallery/alumniMeet",
    NSS: "/gallery/nss",
    OBE_WORKSHOP: "/gallery/workshop",
    STUDENT_WORK_SHOP: "/gallery/Studentworkshop",
  };
  const filterArrayKeys = Object.keys(filterArray);
  const navigate = useNavigate();

  return (
    <div>
      <div className="col-sm-12 ntf_banner_cnt">
        <div className="d-flex align-items-justify  ntf_bg_cnt ">
          <h5 className="ntm_prg">
            <b>APEAPCET/PGECET/ ECET/ICET Code :</b>
            <b style={{ fontWeight: 900 }}> MPLG</b>
          </h5>

          <h5 className="ntm_prg">
            <b style={{ fontWeight: 700 }}>College Code :(JNTUK)</b>
            <b style={{ fontWeight: 900 }}> U9</b>{" "}
          </h5>

          <p className="ntm_prgecap">
            <Link to="http://103.42.248.219/ecap/default.aspx" target="_blank">
              <img className="ecap_logo" src={ecaplogo}></img>
            </Link>
          </p>
          <div>
            <a>
              <Link to="http://smceexamcell.in/examcell/" target="_blank">
                <img className="examsPortal" src={examsPortal}></img>
              </Link>
            </a>
          </div>
        </div>
      </div>
      <div style={{ width: "100%" }}>
        <img src={banner} alt="Banner Sai Spurthi" className="sai_banner_img" />
      </div>
      <header>
        <input type="checkbox" id="menu-bar" />
        <label for="menu-bar">Menu</label>
        <nav className="navbar">
          <ul>
            {/* Home */}
            <li>
              <Link to="/">HOME</Link>
            </li>
            <li>
              <Link to="/about">ADMINISTRATION</Link>
              <ul>
                <li>
                  <Link to="/aboutTrust">About Trust</Link>
                </li>
                <li>
                  <Link to="/Chairman">Chairman</Link>
                </li>
                <li>
                  <Link to="/secretary">Secretary</Link>
                </li>
                <li>
                  <Link to="/director">Director</Link>
                </li>
                <li>
                  <Link to="/VicePresident">Vice-President</Link>
                </li>
                <li>
                  <Link to="/Administration/principal">Principal</Link>
                </li>
              </ul>
            </li>
            <li>
              <Link to="#">ACADEMICS </Link>
              <ul>
                <li>
                  <Link to="#">Academic Regulations</Link>
                  <ul>
                    <li>
                      <Link>UG</Link>
                      <ul>
                        <li>
                          <a href={ARUGMR23} target="_blank">
                            MR23
                          </a>
                        </li>
                        <li>
                          <a href={ARUGR20} target="_blank">
                            R20
                          </a>
                        </li>
                      </ul>
                    </li>
                    <li>
                      <Link>PG</Link>
                      <ul>
                        <li>
                          <a href={ARPGMTech} target="_blank">
                            M.TECH
                          </a>
                        </li>
                        <li>
                          <a href={ARPGMBA} target="_blank">
                            MBA
                          </a>
                        </li>
                      </ul>
                    </li>
                  </ul>
                </li>
                <li>
                  <Link to="/acadamics/acadamicCalender">
                    Academic Calendars
                  </Link>
                </li>
{/* 
                <li>
                  <Link to="#">Contact Us</Link>
                </li> */}

                <li></li>
              </ul>
            </li>
            <li>
              <Link to="/about">ADMISSIONS</Link>
              <ul>
                <li>
                  <Link to="/programesofferd">Programs Offered</Link>
                </li>
                <li>
                  <Link to="/admissionprocedure">Admission Procedure</Link>
                </li>
                <li>
                  <Link to={fee} target="blank">
                    Fee Structure
                  </Link>
                </li>
                <li>
                  <Link to={pdf32} target="blank">
                    B-Category Application
                  </Link>
                </li>
              </ul>
            </li>
            <li>
              <Link to="#">DEPARTMENTS</Link>
              <ul>
                <li>
                  <Link to="/departments/cse">CSE</Link>
                </li>
                <li>
                  <Link to="/departments/ai&ds">CSE-AI</Link>
                </li>
                <li>
                  <Link to="/departments/cse(al&ml)">CSE-DS</Link>
                </li>
                <li>
                  <Link to="departments/it">IT</Link>
                </li>
                <li>
                  <Link to="/departments/ece">ECE</Link>
                </li>
                <li>
                  <Link to="/departments/s&h">S&H</Link>
                </li>
                <li>
                  <Link to="/departments/mba">MBA</Link>
                </li>
              </ul>
            </li>
            <li>
              <Link to="#">EXAMINATION CELL</Link>
              <ul>
                <li>
                  <Link to="/coe">Controller of Examinations (CoE)</Link>
                </li>
                <li>
                  <Link to="#">Examination Timetables</Link>
                </li>
                <li>
                  <Link to="https://jntukresults.edu.in/" target="blank">
                    Results
                  </Link>
                </li>

                <li>
                  <Link to="#">Previous Question Papers</Link>
                  <ul>
                    <li>
                      <a href={PQPBTech} target="_blank">
                        Autonomous
                      </a>
                    </li>
                    <li>
                      <a href={PQPBTech} target="_blank">
                        Jntu-Kakinada
                      </a>
                    </li>
                  </ul>
                </li>
                <li>
                  <Link to="/admissioncontact">Contact Information</Link>
                </li>
              </ul>
            </li>
            <li>
              <Link to="#">STUDENT CORNER</Link>
              <ul>
                <li>
                  <Link to="/amenities/library">Digital Library</Link>
                </li>
                <li>
                  <Link to="/student/SmceStudentGrivanceCell">
                    Student Grievance Cell
                  </Link>
                </li>
                <li>
                  <Link to="/amenities/e-classRooms">Classrooms</Link>
                </li>
                <li>
                  <Link to="/amenities/ictFecilities/lab">Laboratories</Link>
                </li>
                <li>
                  <Link to="/amenities/hostels">Hostel</Link>
                </li>
                <li>
                  <Link to="/amenities/transport">Transport</Link>
                </li>
                <li>
                  <Link to="#">NSS</Link>
                </li>
                <li>
                  <Link to="/HealthCenter">Health Center</Link>
                </li>
                <li>
                  <Link to="#">Auditorium</Link>
                </li>
                <li>
                  <Link to="/amenities/cafeteria">Canteen</Link>
                </li>
              </ul>
            </li>
            <li>
              <Link to="#">STATUTORY BODY </Link>
              <ul>
                <li>
                  <Link to="/governingbody">Governing Body</Link>
                </li>
                <li>
                  <Link to="/academiccouncil">Academic Council</Link>
                </li>
                <li>
                  <Link to="/bos">Board of Studies (BoS)</Link>
                </li>
                <li>
                  <Link to="/finance">Finance Committee </Link>
                </li>
                <li>
                 
                  <Link to="/GoverningBody/AICTEStatutory">AICTE</Link>

                </li>
                <li>
                  <a href={jntu} target="_blank">
                    JNTUK
                  </a>
                </li>
                <li>
                  <a href={nba} target="_blank">
                    NBA
                  </a>
                </li>
                <li>
                  <a href={NaacImg} target="_blank">
                    NAAC
                  </a>
                </li>
                <li>
                  <a href={iso} target="_blank">
                    ISO
                  </a>
                </li>
                <li>
                  <Link to="/iqac">IQAC</Link>
                </li>
                <li>
                  <Link to="#">IIC</Link>
                </li>

                <li>
                  <Link to="/antiRagging">AntiRagging Committee</Link>
                </li>
                <li>
                  <Link to="/committees">College Committees</Link>
                </li>
              </ul>
            </li>
            <li className="naccli">
              <Link to="#">NAAC </Link>
              <ul>
                <li>
                  <Link to="/naac/NaacAQAR">AQAR 2023-24</Link>
                </li>
                <li>
                  <Link to="/naac/naacSsr">AQAR 2022-23</Link>
                </li>

                <li>
                  <Link to="/naac/ict">ICT</Link>
                </li>
                <li>
                  <Link to="/iqac">IQAC</Link>
                </li>
                <li>
                  <Link to="/naac/extendedprofile">Extended Profile</Link>
                </li>
              </ul>
            </li>
            <li>
              <Link to="#">GALLERY </Link>
              <ul>
                <li>
                  <Link to="/gallery/campusPhotos">
                    Mittapalli Mirace Photos
                  </Link>
                </li>
                <li>
                  <Link to="/gallery/alumniMeet">Alumni-Meet-Photos</Link>
                </li>
                <li>
                  <Link to="/gallery/nss">Nss</Link>
                </li>
                <li>
                  <Link to="/gallery/workshop">OBE Work Shops</Link>
                </li>
                <li>
                  <Link to="/gallery/Studentworkshop">Student Work Shops</Link>
                </li>
              </ul>
            </li>
            <li>
                  <Link to="/ContactUspage">CONTACT US</Link>
                </li>
          </ul>
        </nav>
      </header>
      <div className="col-sm-12 notification_banner_cnt">
        <marquee behavior="scroll" direction="left" scrollamount="10">
          <div className="d-flex align-items-center gap-3 notification_bg_cnt">
            <NewReleasesIcon
              className="heartbeat"
              id="notification_icon"
              sx={{ fontSize: 30, color: "yellow" }}
            />
            <p className="notification_prg">
            Admissions for the academic year 2025-26 are in progress, for details contact 9000447117
            </p>
          </div>
        </marquee>
        <Autocomplete
          disablePortal
          options={filterArrayKeys.map((key) => ({
            label: key.replace(/_/g, " "),
            value: key,
          }))} // Map to {label, value}
          sx={{ width: 300 }}
          getOptionLabel={(option) => option.label} // Show label (with spaces) in the dropdown
          renderInput={(params) => <TextField {...params} label="Search" />}
          onChange={(event, option) => {
            if (option && filterArray[option.value]) {
              const url = filterArray[option.value];
              const fileExtension = url.split(".").pop();

              if (fileExtension === "pdf" || !url.startsWith("/")) {
                const anchor = document.createElement("a");
                anchor.href = url;
                anchor.target = "_blank";
                anchor.rel = "noopener noreferrer";

                document.body.appendChild(anchor);
                anchor.click();

                document.body.removeChild(anchor);
              } else {
                navigate(url);
              }
            } else {
              console.error("Invalid option selected", option);
            }
          }}
        />
      </div>
    </div>
  );
};

export default Menubar;
